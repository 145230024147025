<template>
  <div id="sets-receive-setting" class="container">
    <div class="page-header mb-1">
      <strong>ตั้งค่ารับของหวยชุด</strong>
    </div>

    <div class="card border-info">
      <div class="card-header alert-info">
        <ul class="nav nav-tabs card-header-tabs">
          <li v-for="market in markets" :key="market._id" class="nav-item">
            <span class="nav-link" :class="[{'active':tabActiveMarketId===market._id}]" @click="tabActiveMarketId=market._id">{{ market.marketTitle }}</span>
          </li>
        </ul>
      </div>

      <div class="p-2">
        <table class="table-sets-receive table table-bordered table-pp mb-0">
          <thead>
            <tr>
              <th rowspan="2">อัตราจ่าย</th>
              <th width="150" rowspan="2">ราคา/ชุด</th>
              <th v-if="isCompany && rates.length > 1" width="100">เลือก</th>
              <th width="20%">จำนวนรับของ<br />(ชุด/เลข)<br /><span class="text-danger">(0 = ไม่รับของ)</span></th>
              <th width="20%">ซื้อสูงสุดต่อครั้ง<br />(ชุด/เลข)<br /><span class="text-danger">(0 = ไม่รับของ)</span></th>
              <th v-if="isCompany" width="60">แก้ไข</th>
            </tr>
            <tr v-if="isCompany && rates.length > 1">
              <th v-if="rates.length > 1">
                <b-form-checkbox
                  v-model="checkAll"
                  class="m-0"
                  :disabled="!permSetting"
                  :value="true"
                  :unchecked-value="false"
                />
              </th>
              <th class="text-center p-1">
                <b-form-input
                  size="md"
                  :number="true"
                  :disabled="!bulkRateIds.length || !permSetting"
                  class="text-center"
                  v-model="inputBulk.setReceiveAmount"
                />
              </th>
              <th class="text-center p-1">
                <b-form-input
                  size="md"
                  :number="true"
                  :disabled="!bulkRateIds.length || !permSetting"
                  class="text-center"
                  v-model="inputBulk.setMaxBet"
                />
              </th>
              <th class="text-center p-1">
                <b-button
                  variant="success"
                  size="md"
                  :disabled="!bulkRateIds.length || !permSetting"
                  @click="saveBulk"
                >บันทึก</b-button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="rate in rates" :key="rate._id">
              <td class="text-center">{{ rate.rateTitle }}</td>
              <td class="text-center">{{ rate.price | amountFormat(2, '0.00') }}</td>
              <td v-if="isCompany && rates.length > 1" class="text-center">
                <b-form-checkbox
                  v-model="bulkRateIds"
                  :value="rate._id"
                  :disabled="!permSetting"
                />
              </td>
              <td class="text-center p-1">
                <b-form-input
                  v-if="isCompany"
                  size="sm"
                  :number="true"
                  :disabled="!permSetting"
                  class="text-center"
                  v-model="input[rate._id]['setReceiveAmount']"
                />
                <span v-else>{{input[rate._id]['setReceiveAmount']}}</span>
              </td>
              <td class="text-center p-1">
                <b-form-input
                  v-if="isCompany"
                  size="sm"
                  :number="true"
                  :disabled="!permSetting"
                  class="text-center"
                  v-model="input[rate._id]['setMaxBet']"
                />
                <span v-else>{{input[rate._id]['setMaxBet']}}</span>
              </td>
              <td v-if="isCompany" class="text-center p-1">
                <b-button
                  variant="info"
                  size="sm"
                  :disabled="!permSetting"
                  @click="saveRow(rate._id)"
                >save</b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>
<script>
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'
import MarketSettingService from "@/services/MarketSettingService"

export default {
  name: 'SetsReceive',
  data() {
    return {
      data: null,
      tabActiveMarketId: null,
      checkAll: false,
      inputBulk: {
        setMaxBet: '',
        setReceiveAmount: ''
      },
      bulkRateIds: [],
      input: {}
    }
  },
  computed: {
    accSetting() {
      return this.data?.accSets ?? {}
    },
    markets() {
      return this.data?.markets ?? []
    },
    market() {
      if(!this.tabActiveMarketId)
        return null

      return this.markets.find(market=>market._id===this.tabActiveMarketId)
    },
    rates() {
      return (this.data?.rates ?? []).filter((rate)=>{
        return this.accSetting?.[this.tabActiveMarketId]?.rates?.[rate._id]?.isAvailable
      })
      .map((rate)=>{
        return {
          ...rate,
          price: this.accSetting?.[this.tabActiveMarketId]?.rates?.[rate._id]?.price ?? rate.price
        }
      })
    },
    isCompany() {
      return this.$store.state.userProfile.role === 'Company'
    },
    userRole() {
      return this.$store.state.userProfile.userRole
    },
    accountRole() {
      return this.$store.state.userProfile.role
    },
    permSetting() {
      const permission = this.$store.state.account?.permission || {}

      return this.userRole === 'Owner' || permission?.MarketReceive === 'w'
    }
  },
  watch: {
    tabActiveMarketId() {
      this.resetTap()
    },
    checkAll(n, o) {
      this.bulkRateIds = []
      if(n){
        this.bulkRateIds = this.rates.map(rate=>rate._id)
      }
    },
  },
  methods: {
    loadReceiveSetting() {
      MarketSettingService.getMarketSetsReceive()
      .then((response)=>{
        if(response.success) {
          this.data = response.data
          if(!this.tabActiveMarketId) {
            this.tabActiveMarketId = (this.data.markets[0]?._id ?? null)
          }

          this.input = JSON.parse(JSON.stringify(this.data.settings[this.tabActiveMarketId]))
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          title: 'ไม่สำเร็จ!',
          text: 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    },
    resetTap() {
      this.checkAll = false
      this.bulkRateIds = []
      this.inputBulk = {
        setMaxBet: '',
        setReceiveAmount: ''
      }
      this.input = JSON.parse(JSON.stringify(this.data.settings[this.tabActiveMarketId]))
    },
    saveRow(rateId) {
      const numberPattern = /^[0-9]+$/
      if(!numberPattern.test(this.input[rateId]['setReceiveAmount']) || !numberPattern.test(this.input[rateId]['setMaxBet'])) {
        return Swal.fire({
          title: 'ผิดพลาด!',
          text: `กรุณากรอกค่าให้ถูกต้อง`,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      this.save({
        marketId: this.tabActiveMarketId,
        rateIds: [rateId],
        receives: this.input[rateId]
      })
    },
    saveBulk() {
      const numberPattern = /^[0-9]+$/
      if(!numberPattern.test(this.inputBulk['setReceiveAmount']) || !numberPattern.test(this.inputBulk['setMaxBet'])) {
        return Swal.fire({
          title: 'ผิดพลาด!',
          text: `กรุณากรอกค่าให้ถูกต้อง`,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      this.save({
        marketId: this.tabActiveMarketId,
        rateIds: this.bulkRateIds,
        receives: this.inputBulk
      })
    },
    save(data) {
      MarketSettingService.updateMarketSetsReceive(data)
      .then((response)=>{
        if(response.success) {
          this.resetTap()
          this.loadReceiveSetting()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          title: 'ไม่สำเร็จ!',
          text: 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    }
  },
  mounted() {
    this.loadReceiveSetting()
    // if(this.accountRole === 'Company') {
    //   this.loadReceiveSetting()
    // }else{
    //   this.$router.push({name: 'MarketReceive'})
    // }
  }
}
</script>
<style lang="scss" scoped>
.breadcrumb {
  background-color: #FFF;
  border-radius: 10px;
}

.nav-link {
  cursor: pointer;
}

.table-sets-receive {
  thead {
    tr {
      th {
        font-size: 90%;
        text-align: center;
        vertical-align: middle;
        padding: 5px;
      }
    }
  }

  tbody {
    tr {
      td {
        vertical-align: middle;
        text-align: center;
        vertical-align: middle;
        padding: 5px;
      }
    }
  }
}
</style>
